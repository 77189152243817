import { courseIds, lpIds } from './contentIdsForImageRules';

export const IMAGE_RULES = {
  LEARNING_AREA_HEROES_URL: 'https://s3.amazonaws.com/my.ine.com/assets/learning-areas/heroes',
  COURSE_IDS_WITH_LEGACY_BACKGROUNDS: courseIds,
  LEARNING_PATH_IDS_WITH_LEGACY_BACKGROUNDS: lpIds,
};

export const CONTENT_TYPES = {
  COURSE: 'course',
  VIDEO: 'video',
  EXERCISE: 'exercise',
  QUIZ: 'quiz',
  LAB: 'lab',
  LEARNING_PATH: 'learning-path',
  LINK: 'link',
  IFRAME: 'iframe',
};

export const LAB_TYPES = {
  LAB: 'lab',
  STANDARD_LAB: 'standard-lab',
  SKILL_DIVE: 'skill-dive',
  SKILL_CHECK: 'skill-check',
};

export const CONTENT_STATUS = {
  UNSTARTED: 'unstarted',
  STARTED: 'started',
  FINISHED: 'finished',
  UNVISITED: 'unvisited',
  VISITED: 'visited',
  UNSOLVED: 'unsolved',
  PENDING: 'pending',
  COMPLETED: 'completed',
  NON_TRACKABLE: 'non-trackable',
};
