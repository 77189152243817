import {
  CONTENT_TYPE_COLORS,
  IFRAME_TAG,
  LAB_EXPERIENCE_LAB,
  LEARNING_PATH_TAG,
  SKILL_DIVE_LAB,
  STANDARD_LAB_TAG,
} from 'constants/playlists';

import { SessionContext } from 'contexts/session';
import { colors } from 'constants/styles/colors';
import styled from 'styled-components';
import { useContext } from 'react';

export const Tag = styled.span<{ value: string }>`
  font-feature-settings: 'clig' off, 'liga' off;

  font-size: 10px;
  font-style: normal;
  font-weight: 450;
  line-height: 12px;
  text-transform: capitalize;

  span {
    padding: 4px;
  }

  .tag-label {
    color: var(--black-darkest);
    background-color: ${({ value }) => `${CONTENT_TYPE_COLORS[value]}`};
  }
  .tag-ctf {
    background-color: ${colors.dataVisualization.orange.dark};
    color: white;
  }
  .tag-hsc {
    background-color: ${colors.dataVisualization.red.lighter};
    color: #1a2535;
  }
`;

const ContentTypeTag = ({
  value,
  is_lab_experience,
  is_trackable,
  has_skill_check_labs,
}: {
  value: string;
  is_lab_experience?: boolean;
  is_trackable?: boolean;
  has_skill_check_labs?: boolean;
}): JSX.Element => {
  const { hasSkillDive, hasLabExperience } = useContext(SessionContext);

  let showCTF = false;
  let showHasSkillCheck = false;
  let label;

  switch (value) {
    case 'learning-path':
      label = LEARNING_PATH_TAG;
      if (has_skill_check_labs) showHasSkillCheck = true;
      break;
    case 'iframe':
      label = IFRAME_TAG;
      break;
    case 'lab': {
      if (is_lab_experience) {
        label = hasLabExperience && !hasSkillDive ? LAB_EXPERIENCE_LAB : SKILL_DIVE_LAB;
      } else {
        label = STANDARD_LAB_TAG;
      }
      if (is_trackable) showCTF = true;
      break;
    }
    case 'skill-check':
      label = 'Skill Check';
      break;
    default:
      label = value;
      if (has_skill_check_labs) showHasSkillCheck = true;
      break;
  }

  return (
    <Tag value={value}>
      <span className="tag-label">{label}</span>
      {showCTF && <span className="tag-ctf">CTF</span>}
      {showHasSkillCheck && <span className="tag-hsc">Skill Check</span>}
    </Tag>
  );
};

export default ContentTypeTag;
