import { useState } from 'react';
import Tooltip from 'components/tooltip';
import Icon from 'components/icons';
import {
  FilterWithTooltipRadio,
  FilterWithTooltipRadioContainer,
  FilterWithTooltipRadioLabel,
  FilterWithTooltipTooltipWrapper,
  FilterWithTooltipWrapper,
} from './styles';

interface FilterWithTooltipProps {
  onChange: (selected: string) => void;
  options: {
    label: string;
    value: string;
    key?: string;
    tooltip?: string;
  }[];
  value: string | boolean;
}

const FilterWithTooltip = ({ onChange, options }: FilterWithTooltipProps): JSX.Element => {
  const [selected, setSelected] = useState('');

  const handleRadioChange = (value: string) => {
    setSelected(value);
    onChange(value);
  };

  return (
    <FilterWithTooltipWrapper>
      {options.map(({ label, value, tooltip }) => (
        <FilterWithTooltipRadioContainer key={value}>
          <FilterWithTooltipRadio
            value={value}
            checked={selected === value}
            onClick={() => handleRadioChange(value)}
          />
          <FilterWithTooltipRadioLabel>{label}</FilterWithTooltipRadioLabel>

          {tooltip && (
            <FilterWithTooltipTooltipWrapper>
              <Tooltip
                overlayStyle={{ whiteSpace: 'pre-line' }}
                placement="right"
                destroyTooltipOnHide
                title={tooltip}
                zIndex={9999}
              >
                <Icon size="small" fill={'#7965C6'} style={{ cursor: 'pointer' }} icon="Info" />
              </Tooltip>
            </FilterWithTooltipTooltipWrapper>
          )}
        </FilterWithTooltipRadioContainer>
      ))}
    </FilterWithTooltipWrapper>
  );
};

export default FilterWithTooltip;
