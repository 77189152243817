import { useRef, useState } from 'react';

import { IPlaylistContentItem } from 'contexts/playlists/types';
import Icon from 'components/icons';
import Tooltip from 'components/TooltipV2';
import { colors } from 'constants/styles/colors';
import { screenSm } from 'constants/styles/breakpoints';
import styled from 'styled-components';
import { CONTENT_STATUS, CONTENT_TYPES, LAB_TYPES } from 'constants/content/content';

export const ContentProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #57575c;
  gap: 10px;
  margin-left: 20px;
  ${screenSm} {
    margin: 0px;
  }
`;

const BarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 160px;
  height: 4px;
  position: relative;
  background: #f2f2f7;
  border-radius: 30px;
`;

const StatusLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
`;

const Bar = styled.div<{ progress?: number }>`
  width: ${({ progress = 0 }) => progress}%;
  height: 4px;
  position: absolute;
  top: 0;
  left: 0;
  background: #a793f6;
  border-radius: 50px;
`;

const Step = styled.div<{ active: boolean; position?: number }>`
  position: absolute;
  left: ${({ position = 0 }) => position}%;
  transform: translateX(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ active }) => (active ? '#a793f6' : '#f2f2f7')};
`;

const userStatusLabelMap = {
  unstarted: 'Not started',
  started: 'Started',
  finished: 'Completed',
};

const getTooltipText = (contentType, statusKey, progress = null) => {
  let contentTypeLabel;
  if (contentType === CONTENT_TYPES.LEARNING_PATH) {
    contentTypeLabel = 'Learning path';
  } else
    contentTypeLabel = contentType
      .replace(/-/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());

  switch (statusKey) {
    case CONTENT_STATUS.UNSTARTED:
      return `${contentTypeLabel} not started`;
    case CONTENT_STATUS.STARTED:
      return !progress
        ? `${contentTypeLabel} started`
        : `${progress}% of the Skill Check total score achieved`;
    case CONTENT_STATUS.FINISHED:
      return `${contentTypeLabel} complete`;
    case CONTENT_STATUS.NON_TRACKABLE:
      return `This ${contentTypeLabel} only tracks ‘Not started’ and ‘Started’ statuses and doesn’t count towards playlist completion`;
  }
};

const getLabelStatusText = (statusKey, isTrackable, progress = null) => {
  if (!isTrackable && statusKey === CONTENT_STATUS.FINISHED) {
    return userStatusLabelMap.started;
  }
  if (statusKey === CONTENT_STATUS.STARTED && progress) {
    return `Score ${progress}%`;
  }
  return userStatusLabelMap[statusKey];
};

interface IContentProgressBar {
  item: IPlaylistContentItem;
}

const ContentProgressBar: React.FC<IContentProgressBar> = ({ item }) => {
  const progressBarRef = useRef<HTMLDivElement>(null);

  let isTrackable;

  let isStarted;
  let isUnstarted;
  let isCompleted;
  let progress;

  let statusText;
  let userStatusKey;
  let infoTooltipText;

  switch (item.content_type) {
    case CONTENT_TYPES.VIDEO:
      userStatusKey = item.user_status as string;

      isTrackable = item.is_trackable;

      isStarted = userStatusKey === CONTENT_STATUS.STARTED;
      isUnstarted = userStatusKey === CONTENT_STATUS.UNSTARTED;
      isCompleted = userStatusKey === CONTENT_STATUS.FINISHED;

      statusText = getLabelStatusText(userStatusKey, isTrackable);

      infoTooltipText = getTooltipText(item.content_type, userStatusKey);

      progress = item.watched_percentage > 100 ? 100 : item.watched_percentage;

      break;
    case CONTENT_TYPES.LAB: {
      userStatusKey = item.user_status as string;

      isUnstarted = userStatusKey === CONTENT_STATUS.UNSTARTED;
      isStarted = userStatusKey === CONTENT_STATUS.STARTED;
      isCompleted = userStatusKey === CONTENT_STATUS.FINISHED;

      isTrackable = item.is_trackable;

      statusText = getLabelStatusText(userStatusKey, isTrackable);

      if (isTrackable) {
        if (isCompleted) {
          infoTooltipText = 'All flags correctly captured';
          progress = 100;
        } else {
          if (isStarted) {
            progress = Number(((100 / item.flags.length) * item.correct_flags).toFixed(2));
            infoTooltipText = `${item.correct_flags}/${item.flags.length} flags captured`;
          } else {
            progress = 0;
            infoTooltipText = getTooltipText(item.content_type, userStatusKey);
          }
        }
      } else {
        infoTooltipText = getTooltipText(item.content_type, CONTENT_STATUS.NON_TRACKABLE);
      }
      break;
    }
    case LAB_TYPES.SKILL_CHECK: {
      userStatusKey = item.user_status as string;
      isUnstarted = false;
      isStarted = userStatusKey === CONTENT_STATUS.STARTED;
      isCompleted = userStatusKey === CONTENT_STATUS.FINISHED;

      isTrackable = true;
      progress = Math.round((item.correct_flags / item.flags.length) * 100);

      statusText = getLabelStatusText(userStatusKey, isTrackable, progress);

      if (isCompleted) {
        infoTooltipText = 'Skill Check complete';
      } else {
        infoTooltipText = getTooltipText(item.content_type, userStatusKey, progress);
      }
      break;
    }

    case CONTENT_TYPES.LEARNING_PATH:
    case CONTENT_TYPES.COURSE: {
      progress = typeof item.user_status === 'object' ? item.user_status.progress * 100 : 0;

      isUnstarted = progress === 0;
      isStarted = progress > 0;
      isCompleted = progress === 100;
      isTrackable = item.has_trackable_content;

      if (isUnstarted) {
        userStatusKey = CONTENT_STATUS.UNSTARTED;
      } else if (isCompleted) {
        userStatusKey = CONTENT_STATUS.FINISHED;
      } else {
        userStatusKey = CONTENT_STATUS.STARTED;
      }

      statusText = getLabelStatusText(userStatusKey, isTrackable);

      if (isTrackable) {
        if (isCompleted) {
          infoTooltipText = 'All Course content complete';
        } else {
          infoTooltipText = getTooltipText(item.content_type, userStatusKey);
        }
      } else infoTooltipText = getTooltipText(item.content_type, CONTENT_STATUS.NON_TRACKABLE);

      break;
    }
    case CONTENT_TYPES.LINK:
      isCompleted = item.user_status === CONTENT_STATUS.VISITED;
      isUnstarted = item.user_status === CONTENT_STATUS.UNVISITED;
      isTrackable = item.is_trackable;

      if (isCompleted) {
        statusText = 'Opened';
        infoTooltipText = 'URL has been opened';
      } else {
        statusText = 'Not opened';
        infoTooltipText = 'URL has not been opened';
      }

      break;
    case CONTENT_TYPES.QUIZ:
      if (item.user_status === CONTENT_STATUS.PENDING) {
        userStatusKey = CONTENT_STATUS.UNSTARTED;
      } else userStatusKey = item.user_status as string;
      isTrackable = item.is_trackable;

      statusText = getLabelStatusText(userStatusKey, isTrackable);
      infoTooltipText = getTooltipText(
        item.content_type,
        isTrackable ? userStatusKey : CONTENT_STATUS.NON_TRACKABLE
      );

      break;
    case CONTENT_TYPES.EXERCISE:
      if (item.user_status === CONTENT_STATUS.UNSOLVED) {
        userStatusKey = CONTENT_STATUS.UNSTARTED;
      } else userStatusKey = item.user_status as string;
      isTrackable = item.is_trackable;

      statusText = getLabelStatusText(userStatusKey, isTrackable);
      infoTooltipText = getTooltipText(
        item.content_type,
        isTrackable ? userStatusKey : CONTENT_STATUS.NON_TRACKABLE
      );

      break;
  }

  const BarChildComponents = () => {
    switch (item.content_type) {
      case CONTENT_TYPES.VIDEO:
      case CONTENT_TYPES.LEARNING_PATH:
      case CONTENT_TYPES.COURSE:
      case CONTENT_TYPES.IFRAME:
      case CONTENT_TYPES.LAB:
      case LAB_TYPES.SKILL_CHECK: {
        return (
          <>
            <Tooltip content={`${progress}%`} triggerRef={progressBarRef}>
              <Step active position={progress} />
            </Tooltip>
            <Bar progress={progress} />
          </>
        );
      }
      case CONTENT_TYPES.LINK: {
        return (
          <>
            <Step active={isCompleted} position={0} />
            <Step active={isCompleted} position={100} />
            <Bar progress={isCompleted ? 100 : 0} />
          </>
        );
      }
      default: {
        return null;
      }
    }
  };
  return (
    <ContentProgressBarContainer>
      {isTrackable && (
        <BarContainer ref={progressBarRef}>{!isUnstarted && <BarChildComponents />}</BarContainer>
      )}
      <StatusLabelContainer>
        <span>{statusText}</span>
        <Tooltip content={infoTooltipText} placement="topRight">
          <div>
            <Icon
              fill={isTrackable ? colors.iconButtons.purple.dark : '#F8B369'}
              icon="Info"
              size="small"
            />
          </div>
        </Tooltip>
      </StatusLabelContainer>
    </ContentProgressBarContainer>
  );
};

export default ContentProgressBar;
