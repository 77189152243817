import styled from 'styled-components';
import { Radio } from 'antd';
import { screenSm } from 'constants/styles/breakpoints';
import { CONTENT_TYPE_COLORS } from 'constants/playlists';
import { colors } from 'constants/styles/colors';

export const ContentWrapper = styled.div`
  padding: 35px 50px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #1a2535;
  margin-bottom: 5px;
`;

export const SubTitle = styled.div`
  color: #111a27;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
`;

export const ContentItemsContainer = styled.div`
  height: 500px;
  width: 100%;
  overflow-y: scroll;
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  background: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyLighter};
  border-radius: 10px;
  padding: 20px;
  min-height: 40vh;
  flex: 1;
`;

export const ContainerTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #111a27;
  margin-bottom: 10px;
`;

export const CourseItem = styled.div<{ contentType?: string }>`
  display: flex;
  align-items: center;
  font-weight: 450;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  padding: 5px 8px;
  background: #111a27;
  margin-bottom: 5px;
  border-radius: 60px;
  width: fit-content;
  gap: 6px;
  p {
    margin: 0px;
  }
  span {
    text-transform: capitalize;
    color: ${({ contentType }) => CONTENT_TYPE_COLORS[contentType]};
  }
  .tag-scl {
    color: ${colors.dataVisualization.red.lighter};
  }
`;

export const FiltersWrapper = styled.div<{ columns: string }>`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || 'repeat(4, 1fr)'};
  margin-top: 10px;
  gap: 10px;
`;

export const SearchResultsWrapper = styled.ul`
  height: 500px;
  overflow: auto;
  margin-top: 20px;
  padding: 0;
`;

export const ContentListItem = styled.li`
  display: flex;
  padding-bottom: 12px;
  padding-right: 12px;
  border-bottom: 1px solid #f2f2f7;
  margin-bottom: 12px;
  align-items: center;
  justify-content: space-between;
  &:last-child {
    border-bottom: none;
  }
`;
export const ContentRowRightWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  margin-left: auto;
`;

export const AddContentLeftContainer = styled.div`
  width: 100%;
  ${screenSm} {
    width: 55%;
  }
`;

export const AddContentRightContainer = styled.div`
  display: none;
  padding: 20px;
  background: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyLighter};
  border-radius: 10px;
  min-height: 30vh;
  flex: 1;

  ${screenSm} {
    display: flex;
    margin-left: 20px;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

export const HitLabel = styled.span<{ disabled: boolean }>`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ disabled }) => (disabled ? colors.fonts.lightGray : colors.fonts.dark)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const FilterWithTooltipWrapper = styled.div`
  display: flex;
  width: 500px;
  gap: 20px;
`;

export const FilterWithTooltipRadioContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterWithTooltipTooltipWrapper = styled.div`
  margin-left: 5px;
`;

export const FilterWithTooltipRadioLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const FilterWithTooltipRadio = styled(Radio)`
  .ant-radio-inner {
    width: 20px;
    height: 20px;
    background-color: ${colors.palette.Business_LightThemeGreyLighter};
    border: none;
  }
  .ant-radio-inner::after {
    background-color: ${colors.palette.Business_DataVizLilacLighter};
  }
  .ant-radio-checked {
    width: 20px;
    height: 20px;
    border: none;
  }
  .ant-radio-checked::after {
    border: none;
  }
`;
