import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import algoliasearch from 'algoliasearch';
import cloneDeep from 'lodash.clonedeep';
import { v4 as uuidv4 } from 'uuid';
import { Configure, InstantSearch } from 'react-instantsearch-hooks';
import './statics.scss';
import {
  AddContentLeftContainer,
  AddContentRightContainer,
  ContainerTitle,
  ContentItemsContainer,
  CourseItem,
  FiltersWrapper,
  IconWrapper,
  LinksContainer,
} from './styles';
import {
  HeaderContainer,
  ModalActions,
  ModalContainer,
  ModalContent,
  ModalSection,
} from 'components/modal/styles';
import { IPlaylist, IPlaylistLink } from 'contexts/playlists/types';
import Button from 'components/button';
import { FIRST_PLAYLIST_CATEGORY } from 'constants/settings';
import { INE_UUID } from 'constants/settings';
import Icon from 'components/icons';
import LinkItem from './LinkItem';
import Modal from 'components/modal';
import Notification from 'components/notification';
import { SearchInput } from './SearchInput';
import { SearchResults } from './SearchResults';
import { SessionContext } from 'contexts/session';
import { StyledInput } from 'components/inputs/styles';
import Tabs from 'components/tabs';
import { bulkCreateLinks } from 'services/links';
import { editPlaylist } from 'services/playlists';
import useCategoriesContent from 'hooks/useCategoriesContent';
import useLearningAreasContent from 'hooks/useLearningAreasContent';
import {
  CONTENT_TYPES,
  HAS_SKILL_CHECK_LAB,
  LEARNING_PATH_TAG,
  SKILL_DIVE_LAB,
  STANDARD_LAB_TAG,
} from 'constants/playlists';
import InputSelectCheckbox from 'components/inputs/inputSelectCheckbox';
import FilterWithTooltip from './FilterWithTooltip';

interface IAddContentPlaylist {
  isVisible?: boolean;
  playlist: IPlaylist;
  category: string;
  onClose?: (playlist?: IPlaylist) => void;
  onGoBack?: () => void;
}

export interface IContentItem {
  id: string;
  name: string;
  content_type: string;
  objectID: string;
  slug: string;
  description: string;
  difficulty: string;
  duration: string;
  instructors: { name: string }[];
  amount_of_videos: number;
  amount_of_quizzes: number;
  amount_of_labs: number;
  amount_of_exercises: number;
  is_lab_experience?: boolean;
  has_skill_check_labs?: boolean;
}

export interface IFilters {
  content_type: string[];
  difficulty: string[];
  'categories.name': string[];
  'learning_areas.name': string[];
  is_lab_experience?: boolean;
  has_skill_check_labs?: boolean;
}

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_APP_KEY
);

const tabItemsState = [
  { name: 'Add INE Content', value: 'content', counter: 0 },
  { name: 'Add External Link', value: 'link', counter: 0 },
];

const reg = new RegExp('https*:\\/\\/');

const contentTypeOptions = {
  '': { label: 'All content', value: '' },
  course: { label: 'Courses', value: CONTENT_TYPES.COURSE },
  lab: { label: 'Labs', value: CONTENT_TYPES.LAB },
  learningPath: { label: 'Learning Paths', value: CONTENT_TYPES.LEARNING_PATH },
  video: { label: 'Videos', value: CONTENT_TYPES.VIDEO },
};

const difficultyOptions = {
  '': { label: 'All levels', value: '' },
  informative: { label: 'Informative', value: 'Informative' },
  novice: { label: 'Novice', value: 'Novice' },
  professional: { label: 'Professional', value: 'Professional' },
  advanced: { label: 'Advanced', value: 'Advanced' },
  expert: { label: 'Expert', value: 'Expert' },
};

const labOptions = {
  '': { label: 'All labs', value: '' },
  standard: {
    label: 'Standard Labs',
    value: 'false',
    tooltip: 'All labs on the INE Platform, except those in the Skill Dive library.',
  },
  skillDive: {
    label: 'Skill Dive Labs',
    value: 'true',
    tooltip: 'A curated selection of specialized labs within the INE platform.',
  },
};

const skillCheckOptions = {
  '': { label: 'All content', value: '' },
  skillCheck: {
    label: 'Content with Skill Check',
    value: 'true',
    tooltip: 'Show only content that includes a\n Skill Check lab(s) for skill validation',
  },
};

const AddContent: React.FC<IAddContentPlaylist> = ({ isVisible, playlist, category, onClose }) => {
  const title = 'Add content to your playlist';
  const ctaLabel = 'Add content';
  const [contentList, setContentList] = useState<Record<string, IContentItem>>({});
  const [linksList, setLinksList] = useState<Record<string, IPlaylistLink>>({});
  const [newLink, setNewLink] = useState('');
  const isLinkValid = reg.test(newLink);
  const showInvalidLink = newLink.length && !isLinkValid;
  const [tabItems, setTabItems] = useState(tabItemsState);
  const initFilters = {
    content_type: [],
    difficulty: [],
    'categories.name': [],
    'learning_areas.name': [],
  };
  const [filters, setFilters] = useState<IFilters>(initFilters);
  const { learningAreas, getLearningAreas } = useLearningAreasContent();
  const learningAreaOptions = learningAreas.reduce(
    (acc, { name }) => {
      return { ...acc, [name]: { value: name, label: name } };
    },
    { '': { value: '', label: 'All' } }
  );
  const { categories, getCategories } = useCategoriesContent();
  const categoryOptions = categories.reduce(
    (acc, { name }) => {
      return { ...acc, [name]: { value: name, label: name } };
    },
    { '': { value: '', label: 'All' } }
  );
  const [facetFilters, setFacetFilters] = useState([]);
  const [selectedTab, setSelectedTab] = useState(tabItems[0].value);
  const [loading, setLoading] = useState(false);
  const { account } = useContext(SessionContext);
  const business_account_id = account?.id;
  const [contentAlreadyInPlaylist, setContentAlreadyInPlaylist] = useState<
    Record<string, IContentItem>
  >({});

  const enabledContentTypes = [CONTENT_TYPES.COURSE, CONTENT_TYPES.LEARNING_PATH];

  useEffect(() => {
    const obj = {};

    for (const section of playlist.content) {
      for (const item of section.content) {
        obj[item.uuid] = item;
      }
    }

    setContentAlreadyInPlaylist(obj);
  }, [playlist.content]);

  useEffect(() => {
    setTabItems((prevState) => {
      const newState = [...prevState];
      const tab = newState.find((tabItem) => tabItem.value === 'content');

      tab.counter = Object.keys(contentList).length;

      return newState;
    });
  }, [Object.keys(contentList).length]);

  useEffect(() => {
    setTabItems((prevState) => {
      const newState = [...prevState];
      const tab = newState.find((tabItem) => tabItem.value === 'link');

      tab.counter = Object.keys(linksList).length;

      return newState;
    });
  }, [Object.keys(linksList).length]);

  useEffect(() => {
    const { content_type } = filters;
    setFacetFilters(() => {
      if (content_type.length === 0) return [];
      const values = [];
      if (typeof filters['is_lab_experience'] !== 'undefined') {
        values.push(['content_type:Lab'], [`is_lab_experience:${filters['is_lab_experience']}`]);
        return values;
      } else if (typeof filters['has_skill_check_labs'] !== 'undefined') {
        const content_types = content_type.map(
          (ct) => `content_type:${ct === CONTENT_TYPES.LEARNING_PATH ? 'learning-path' : ct}`
        );
        values.push(content_types);
        values.push(['has_skill_check_labs:true']);
        return values;
      } else if (allValuesPresent(filters.content_type, enabledContentTypes)) {
        Object.entries(filters).map(([key, value]) => {
          const newarr = value.map(
            (v) => `${key}:${v.includes(CONTENT_TYPES.LEARNING_PATH) ? 'learning-path' : v}`
          );
          newarr.length > 0 && values.push(newarr);
        });
        return values;
      } else {
        const content_types = content_type.map(
          (ct) => `content_type:${ct === CONTENT_TYPES.LEARNING_PATH ? 'learning-path' : ct}`
        );
        values.push(content_types);
        return values;
      }
    });
  }, [filters]);

  useEffect(() => {
    getLearningAreas();
    getCategories();
  }, []);

  const allValuesPresent = (from: string[], to: string[]) => {
    return from.length > 0 && from.every((v) => to.includes(v));
  };

  const handleFilterChange = (filter, value) => {
    filter === 'content_type' && !allValuesPresent(value, enabledContentTypes)
      ? setFilters(() => ({ ...initFilters, [filter]: value }))
      : setFilters((prevState) => ({ ...prevState, [filter]: value }));
  };

  const handleFilterWithTooltipChange = (value) => {
    if (value !== '') {
      setFilters((prevState) => ({ ...prevState, is_lab_experience: value === 'true' }));
    } else {
      setFilters(() => ({ ...initFilters, content_type: ['Lab'] }));
    }
  };

  const handleHasSkillCheckFilterChange = (value) => {
    if (value !== '') {
      setFilters((prevState) => ({ ...prevState, has_skill_check_labs: value === 'true' }));
    } else {
      setFilters((prevState) => {
        const newState = { ...prevState };
        delete newState.has_skill_check_labs;
        return newState;
      });
    }
  };
  const onAddLink = async () => {
    let titleSite;
    let descriptionSite;
    let logoSite;

    try {
      const response = await fetch(newLink);
      const text = await response.text();
      const newdoc = document.createRange().createContextualFragment(text);

      titleSite = newdoc.querySelector('meta[property="og:title"]')?.getAttribute('content');

      descriptionSite = newdoc
        .querySelector('meta[property="og:description"]')
        ?.getAttribute('content');

      logoSite = newdoc.querySelector('meta[property="og:image"]')?.getAttribute('content');
    } catch (error) {
      if (error?.response?.data?.details) {
        Notification({ text: error.response.data.details, type: 'error' });
      }
    }
    setLinksList((prevState) => ({
      [uuidv4()]: {
        name: titleSite || newLink,
        description: descriptionSite || '',
        link_url: newLink,
        logo_image_url: logoSite || '',
      },
      ...prevState,
    }));
    setNewLink('');
  };

  const onSaveLink = (objectID: string, link: IPlaylistLink) => {
    setLinksList((prevState) => ({
      ...prevState,
      [objectID]: { ...link },
    }));
  };

  const onDeleteLink = (objectID: string) => {
    setLinksList((prevState) => {
      const newState = { ...prevState };

      delete newState[objectID];

      return newState;
    });
  };

  const generateContentPayload = async () => {
    let normalizedContent = cloneDeep(playlist.content);
    if (!normalizedContent || !normalizedContent.length) {
      normalizedContent = [
        {
          name: FIRST_PLAYLIST_CATEGORY,
          content_type: 'group',
          content: [],
        },
      ];
    }

    for (const section of normalizedContent) {
      section.content = section.content.map(({ uuid, content_type }) => ({
        uuid,
        content_type,
      }));

      if (section.name === category) {
        // add new content
        for (const [key, { content_type }] of Object.entries(contentList)) {
          section.content.push({
            uuid: key,
            content_type,
          });
        }

        // add new links
        if (Object.keys(linksList).length) {
          const savedLinks: IPlaylistLink[] | undefined = await handleBulkPlaylistLinks();

          if (savedLinks && savedLinks.length) {
            for (const savedLink of savedLinks) {
              section.content.push({
                uuid: savedLink.id,
                content_type: 'link',
              });
            }
          }
        }
      }
    }

    return normalizedContent;
  };

  const handleConfirm = async () => {
    setLoading(true);

    const reqData: IPlaylist = {
      id: playlist.id,
      name: playlist.name,
      color: playlist.color,
      content: await generateContentPayload(),
      business_account_id,
      company: INE_UUID, // INE always
    };

    handleAddContent(reqData);
  };

  const handleBulkPlaylistLinks = async (): Promise<IPlaylistLink[] | undefined> => {
    try {
      const response = await bulkCreateLinks({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        links: Object.entries(linksList).map(([objectID, link]) => link),
        business_account_id,
      });
      if (response.status === 201) {
        Notification({ text: `Links have been created`, type: 'success' });
        return response.data;
      } else {
        Notification({ text: 'There has been a problem creating your links', type: 'error' });
      }
    } catch (error) {
      if (error?.response?.data?.details) {
        Notification({ text: error.response.data.details, type: 'error' });
      }
    }
  };

  const handleAddContent = async (data: IPlaylist) => {
    try {
      const response = await editPlaylist(data);
      if (response.status === 200) {
        Notification({ text: `${response.data.name} has been updated`, type: 'success' });
        onClose(response.data);
        return;
      } else {
        Notification({ text: 'There has been a problem editing your playlist', type: 'error' });
      }
    } catch (error) {
      if (error?.response?.data?.details) {
        Notification({ text: error.response.data.details, type: 'error' });
      }
    }
    setLoading(false);
    onClose();
  };

  const handleCheckContent = (hit: IContentItem) => {
    const findRow = contentList[hit.objectID];

    if (findRow) {
      setContentList((prevState) => {
        const copiedSelected = { ...prevState };

        delete copiedSelected[hit.objectID];

        return copiedSelected;
      });
    } else {
      setContentList((prevState) => ({
        ...prevState,
        [hit.objectID]: { ...hit },
      }));
    }
  };
  const onRemoveCourse = (courseId) => {
    setContentList((prevState) => {
      const newState = { ...prevState };
      delete newState[courseId];
      return newState;
    });
  };

  const getContentLabel = (content: any) => {
    if (content.is_lab_experience) {
      return SKILL_DIVE_LAB;
    } else {
      switch (content.content_type) {
        case 'learning-path':
          return LEARNING_PATH_TAG;
        case 'lab':
          return STANDARD_LAB_TAG;
        default:
          return content.content_type;
      }
    }
  };

  return (
    <Modal
      centered
      visible={isVisible}
      fullscreen={isMobile}
      onCancel={() => onClose()}
      width={isMobile ? '100%' : '70vw'}
      footer={null}
      closeIconColor={'#fff'}
      maskClosable={false}
      className="add-content"
    >
      <ModalContainer>
        <HeaderContainer>
          <h2>{title}</h2>
        </HeaderContainer>
        <ModalContent>
          <Tabs
            items={tabItems}
            selected={selectedTab}
            onTabChange={(tab) => setSelectedTab(tab)}
          />

          {selectedTab === 'content' && (
            <ModalSection style={{ display: 'flex' }}>
              <AddContentLeftContainer>
                <InstantSearch
                  searchClient={searchClient}
                  indexName={process.env.REACT_APP_ALGOLIA_SEARCH_INDEX}
                >
                  <FiltersWrapper columns="2.7fr 1.3fr">
                    <SearchInput />
                    <InputSelectCheckbox
                      values={filters.content_type}
                      placeHolder={'Content Type'}
                      options={Object.values(contentTypeOptions)}
                      onChange={(v) => handleFilterChange('content_type', v)}
                      confirmBtnLabel={'Apply filter'}
                    />
                  </FiltersWrapper>

                  {allValuesPresent(filters.content_type, enabledContentTypes) && (
                    <>
                      <FiltersWrapper columns="1fr 1fr 1fr">
                        <InputSelectCheckbox
                          values={filters['learning_areas.name']}
                          placeHolder={'Learning Area'}
                          options={Object.values(learningAreaOptions)}
                          onChange={(v) => handleFilterChange('learning_areas.name', v)}
                          confirmBtnLabel={'Apply filter'}
                        />
                        <InputSelectCheckbox
                          values={filters.difficulty}
                          placeHolder={'Difficulty'}
                          options={Object.values(difficultyOptions)}
                          onChange={(v) => handleFilterChange('difficulty', v)}
                          confirmBtnLabel={'Apply filter'}
                        />
                        <InputSelectCheckbox
                          values={filters['categories.name']}
                          placeHolder={'Category'}
                          options={Object.values(categoryOptions)}
                          onChange={(v) => handleFilterChange('categories.name', v)}
                          confirmBtnLabel={'Apply filter'}
                        />
                      </FiltersWrapper>
                      <FiltersWrapper columns="1fr 1fr">
                        <FilterWithTooltip
                          value={filters['has_skill_check_labs']}
                          options={Object.values(skillCheckOptions)}
                          onChange={(v) => handleHasSkillCheckFilterChange(v)}
                        />
                      </FiltersWrapper>
                    </>
                  )}
                  {filters.content_type.length === 1 &&
                    filters.content_type[0] === CONTENT_TYPES.LAB && (
                      <FiltersWrapper columns="1fr 1fr">
                        <FilterWithTooltip
                          value={filters['is_lab_experience']}
                          options={Object.values(labOptions)}
                          onChange={(v) => handleFilterWithTooltipChange(v)}
                        />
                      </FiltersWrapper>
                    )}
                  <Configure facetFilters={facetFilters} page={0} />
                  <SearchResults
                    contentToExclude={contentAlreadyInPlaylist}
                    selectedContent={contentList}
                    onCheckContent={handleCheckContent}
                  />
                </InstantSearch>
              </AddContentLeftContainer>
              <AddContentRightContainer>
                <ContentItemsContainer>
                  <ContainerTitle>Your selections</ContainerTitle>
                  {Object.entries(contentList).map(([uuid, content]) => {
                    return (
                      <CourseItem key={uuid} contentType={content.content_type}>
                        <p>{content.name}</p>
                        <span>{getContentLabel(content)}</span>
                        {content.has_skill_check_labs && (
                          <span className="tag-scl">{HAS_SKILL_CHECK_LAB}</span>
                        )}
                        <IconWrapper onClick={() => onRemoveCourse(uuid)}>
                          <Icon size="small" stroke="#A793F6" fill={'#A793F6'} icon="Close" />
                        </IconWrapper>
                      </CourseItem>
                    );
                  })}
                </ContentItemsContainer>
              </AddContentRightContainer>
            </ModalSection>
          )}

          {selectedTab === 'link' && (
            <ModalSection>
              <div className="add-content__add-link-wrapper">
                <StyledInput
                  placeholder="Type or paste url"
                  value={newLink}
                  onChange={(e) => setNewLink(e.target.value)}
                />
                <Button
                  icon="Link"
                  disabled={!newLink.length || !isLinkValid}
                  $secondary
                  onClick={onAddLink}
                >
                  Add link
                </Button>
              </div>
              <div className="add-content__add-link-error-wrapper">
                {showInvalidLink ? (
                  <>
                    <Icon icon="Cancel" />
                    <p>Incorrect URL format. Please try again.</p>
                  </>
                ) : null}
              </div>
              <LinksContainer>
                <ContainerTitle>Your links</ContainerTitle>
                {Object.entries(linksList).map(([id, link]) => {
                  return (
                    <LinkItem
                      key={id}
                      data={link}
                      onSave={(linkToSave) => onSaveLink(id, linkToSave)}
                      onDelete={() => onDeleteLink(id)}
                    />
                  );
                })}
              </LinksContainer>
            </ModalSection>
          )}
        </ModalContent>

        <ModalActions>
          <Button
            disabled={loading}
            $primary
            style={{ marginLeft: 'auto' }}
            onClick={handleConfirm}
          >
            {ctaLabel}
          </Button>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default AddContent;
