import { colors } from './styles/colors';

export const CONTENT_TYPES = {
  COURSE: 'Course',
  VIDEO: 'Video',
  QUIZ: 'Quiz',
  SLIDE: 'Slide',
  LAB: 'Lab',
  LINK: 'Link',
  LEARNING_PATH: 'Learning Path',
  SKILL_DIVE: 'Skill Dive',
};

export const PLAYLIST_TYPE = {
  SONAR_RECOMMENDED: 'sonar_recommended',
  SONAR_GOAL: 'sonar_goal',
  STANDARD: 'B2B',
};

export const LEARNING_PATH_SHORT_SLUG = 'path';

export const CONTENT_TYPE_COLORS = {
  course: colors.dataVisualization.purple.lighter,
  video: colors.dataVisualization.yellow.lighter,
  quiz: colors.dataVisualization.blue.lighter,
  slides: colors.dataVisualization.green.lighter,
  lab: colors.dataVisualization.orange.lighter,
  link: colors.dataVisualization.gray.dark,
  'learning-path': colors.dataVisualization.pink.lighter,
  exercise: colors.dataVisualization.beige.lighter,
  'skill-check': colors.dataVisualization.red.lighter,
};

export const LEARNING_AREAS_TYPES = {
  cloud: { id: 'd3f82c70-f4e7-428e-afd7-4b1d28988e18', name: 'Cloud' },
  cyberSecurity: { id: '3e1aa06f-2e9f-4789-b50d-aa027ad8dcfa', name: 'Cyber Security' },
  dataScience: { id: 'db4652c5-2a80-4be8-a8f1-8852cf0e5230', name: 'Data Science' },
  development: { id: '47306190-b47d-46d5-bbf7-06ab3cdca942', name: 'Development' },
  itEssentials: { id: 'ff0cb823-5bec-4b3a-b096-415dcf6c0adb', name: 'IT Essentials' },
  networking: { id: 'e73fd5a8-2ead-4159-9a25-38b50ad4ab20', name: 'Networking' },
};

export const SKILL_DIVE_EXPERIENCE_TEXT = 'This lab is part of Skill Dive.';

export const LAB_EXPERIENCE_TEXT = 'This lab is part of Lab Experience.';

export const SKILL_DIVE_LAB = 'Skill Dive Lab';

export const LAB_EXPERIENCE_LAB = 'Lab Experience Lab';

export const LEARNING_PATH_TAG = 'Learning Path';

export const IFRAME_TAG = 'Slides';

export const STANDARD_LAB_TAG = 'Standard Lab';

export const HAS_SKILL_CHECK_LAB = 'Skill Check';
